import 'whatwg-fetch';
export { tns } from "../../../node_modules/tiny-slider/src/tiny-slider";

const nth = function (date) {
  const d = date.getDate();
  const dString = String(d);
  const last = +dString.slice(-2);
  if (last > 3 && last < 21) {
    return dString + 'th'
  }

  if (last % 10 === 1) {
    return dString + 'st';
  } else if (last % 10 === 2) {
    return dString + 'nd';
  } else if (last % 10 === 3) {
    return dString + 'rd';
  } else {
    return dString + 'th';
  }
}

const monthStr = function (date) {
  const month = date.getMonth();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ]

  return months[month];
}

export const formatDate = function (date) {
  const formattedYear = date.getFullYear();
  const formattedmonth = monthStr(date);
  const formattedDate = nth(date);
  let formattedDateTime = formattedmonth + ' ' + formattedDate + ', ' + formattedYear;

  return formattedDateTime;
}

export const formatAMPM = function (date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  let strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

export const mapOnCreate = function () {
  const cardInfo = (info) => {
    let primarySpecialty;
    let locationName;

    if (info.c_specialtyList && info.c_specialtyList.length) {
      for (const specialty of info.c_specialtyList) {
        if (typeof specialty === 'object' && specialty.primarySpecialty
        && specialty.linkedSpecialty && specialty.linkedSpecialty.length
        && specialty.linkedSpecialty[0] && (typeof specialty.linkedSpecialty[0] === 'object')) {
          primarySpecialty = specialty.linkedSpecialty[0].name;
        }
      }
    }

    if (info.c_linkedLocation && info.c_linkedLocation.length
    && (typeof info.c_linkedLocation[0] === 'object')) {
      locationName = info.c_linkedLocation[0].name;
    }

    const addressLink = Formatter.getDirectionsUrl(info);
    const phone = info.mainPhone.substring(2, 5) + '.' + info.mainPhone.substring(5, 8) + '.' + info.mainPhone.substring(8);

    return `
      <div class="Popup-row">
        ${locationName  ? '<div class="Popup-locationName">' : ''}
          ${locationName ? locationName : ''}
        ${locationName ? '</div>' : ''}
        <div class="Popup-addressWrapper">
          <div class="Popup-address">
            ${Formatter.address(info)}
          </div>
          ${addressLink ? '<a class="Popup-addressLink" data-eventtype="DRIVING_DIRECTIONS" target="_blank" rel="noopener noreferrer" href=' + addressLink + '>' : ''}
            <div class="Popup-getDirection">
              ${"Get Directions"}
            </div>
          ${addressLink ? '</a>' : ''}
        </div>
        ${phone ? '<div class="Popup-phoneWrapper">' : ''}
          <div class="Popup-phoneText">
            Phone:
          </div>
          ${phone}
        ${phone ? '</div>' : ''}
      </div>
    `;
  }

  const cardTemplate = (locations) => {
    const cross = `<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
      <path d="M8.87422 7.50011L14.7149 1.65911C15.095 1.27918 15.095 0.664882 14.7149 0.28495C14.335 -0.0949832 13.7207 -0.0949832 13.3408 0.28495L7.49991 6.12595L1.65921 0.28495C1.27911 -0.0949832 0.665002 -0.0949832 0.285077 0.28495C-0.0950257 0.664882 -0.0950257 1.27918 0.285077 1.65911L6.12578 7.50011L0.285077 13.3411C-0.0950257 13.721 -0.0950257 14.3353 0.285077 14.7153C0.474417 14.9048 0.72337 15 0.972145 15C1.22092 15 1.46969 14.9048 1.65921 14.7153L7.49991 8.87428L13.3408 14.7153C13.5303 14.9048 13.7791 15 14.0279 15C14.2766 15 14.5254 14.9048 14.7149 14.7153C15.095 14.3353 15.095 13.721 14.7149 13.3411L8.87422 7.50011Z" fill="#005C80"/>
    </svg>`

    return `
      <div class="Popup">
        <div class="Popup-closeWrapper">
          <button class="Popup-close js-popup-close">
            ${cross}
            <span class="sr-only">
              close
            </span>
          </button>
        </div>
        <div class="Popup-body js-popup-body">
          ${cardInfo(locations[0])}
        </div>
      </div>
    `
  }

  this._map = this.getProviderInstance(this._mapProvider);
  this._map._collapseMarkers = (markers) => {
    const locationToItem = {};
    markers.forEach(m => {
      locationToItem[`${m.latitude.toFixed(3)}${m.longitude.toFixed(3)}`]
        ? locationToItem[`${m.latitude.toFixed(3)}${m.longitude.toFixed(3)}`].push(m)
        : locationToItem[`${m.latitude.toFixed(3)}${m.longitude.toFixed(3)}`] = [m];
    });

    const collapsedMarkers = [];
    for (let [, markers] of Object.entries(locationToItem)) {
      if (markers.length > 1) {
        const collapsedMarker = {
          item: markers.map(m => m.item),
          label: markers.length,
          latitude: markers[0].latitude,
          longitude: markers[0].longitude
        };
        collapsedMarkers.push(collapsedMarker);
      } else {
        collapsedMarkers.push(markers[0]);
      }
    }

    return collapsedMarkers;
  }

  this._map.loadJS = (onLoad) => {
    let script = document.createElement('script');
    script.id = 'yext-map-js';
    script.onload = () => {
      this._map._isLoaded = true;
      mapboxgl.accessToken = this._map._apiKey;

      if (typeof onLoad === 'function') {
        onLoad();
      }

      if (typeof this._map._onLoaded === 'function') {
        this._map._onLoaded();
      }
    };
    script.async = true;
    script.src = 'https://api.mapbox.com/mapbox-gl-js/v1.11.1/mapbox-gl.js';

    let css = document.createElement('link');
    css.id ='yext-map-css';
    css.rel = 'stylesheet';
    css.href = 'https://api.mapbox.com/mapbox-gl-js/v1.11.1/mapbox-gl.css';

    document.body.appendChild(css);
    document.body.appendChild(script);
  }

  this._map.init = function (el, mapData, resultsContext) {
    if ((!mapData || mapData.mapMarkers.length <= 0) && !this._showEmptyMap) {
      this._map = null;
      return this;
    }

    let container = el;
    this._map = new mapboxgl.Map({
      container: container,
      zoom: this._zoom,
      style: 'mapbox://styles/mapbox/streets-v9',
      center: this.getCenterMarker(mapData)
    });

    this._map.addControl(new mapboxgl.NavigationControl({showCompass: false}));

    if (mapData && mapData.mapMarkers.length) {
      const collapsedMarkers = this._collapsePins
        ? this._collapseMarkers(mapData.mapMarkers)
        : mapData.mapMarkers;

      const bounds = new mapboxgl.LngLatBounds();
      for (const marker of collapsedMarkers) {
        const itemArray = Array.isArray(marker.item) ? marker.item : [marker.item];
        const ids = itemArray.map(item => item.id);
        const cards = document.querySelectorAll(ids.map(id => '.js-card-' + id).join(','));

        const cardsById = Array.from(cards).reduce((m, card) => {
          m[card.dataset.id] = card;
          return m;
        }, {});

        const coords = [marker.longitude, marker.latitude];
        const popup = new mapboxgl.Popup({
          closeButton: false,
          offset: {
            'left': [12, -36],
            'right': [-12, -36],
            'bottom': [0, -64],
            'bottom-left': [12, -48],
            'bottom-right': [-12, -48],
          },
          anchor: 'bottom'
        });
        const parser = new DOMParser();
        const pinSVG = `
        <svg class="Map-pin Map-pin--default" width="32" height="48" viewBox="0 0 33 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.0815 1C20.3842 1 24.2795 2.74402 27.0992 5.56371C29.9189 8.3834 31.6629 12.2788 31.6629 16.5815C31.6629 22.3904 26.4317 33.1926 16.0807 49C5.73056 33.1913 0.5 22.39 0.5 16.5815C0.5 12.2788 2.24402 8.3834 5.06371 5.56371C7.8834 2.74402 11.7788 1 16.0815 1Z" fill="#3E4345" stroke="black" stroke-opacity="0.5"/>
          <ellipse cx="16.5" cy="16.5" rx="5" ry="5" fill="white"/>
        </svg>`;

        const doc = parser.parseFromString(`<div class="js-map-pin">${pinSVG}</div>`, 'text/html');
        const el = doc.body.firstChild;
        const mapMarker = new mapboxgl.Marker({element: el, anchor: 'bottom'})
          .setLngLat(coords)
          .setPopup(popup);

        bounds.extend(mapMarker.getLngLat());
        mapMarker.addTo(this._map);

        let popupIdx = 0;

        const pins = document.querySelectorAll('.js-map-pin');

        popup.on('open', () => {
          popup.setHTML(cardTemplate(itemArray));

          // Scroll the teaser for the clicked mappin into view

          const prevButton = popup.getElement().querySelector('.js-popup-prev');
          const nextButton = popup.getElement().querySelector('.js-popup-next');
          prevButton && prevButton.addEventListener('click', () => updatePopup(popupIdx-1));
          nextButton && nextButton.addEventListener('click', () => updatePopup(popupIdx+1));

          let preselected = document.querySelector('.yxt-Answers-component.is-active');
          if (preselected) {
            preselected.classList.remove('is-active');
          }

          el.classList.add('is-clicked');
          const closeButton = popup.getElement().querySelector('.js-popup-close');
          closeButton.addEventListener('click', (e) => {
            el.classList.remove('is-clicked');
            mapMarker.getPopup().remove();
          });
        });


        popup.on('close', () => {
          cards.forEach(card => card.classList.remove('is-clicked'));
          pins.forEach(pin => pin.classList.remove('is-clicked'));
        })

        mapMarker.getElement().addEventListener('click', (e) => {
          if (el.classList.contains('is-clicked')) {
            el.classList.add('is-clicked');
          } else {
            el.classList.remove('is-clicked');
          }

          pins.forEach(pin => pin != el && pin.classList.remove('is-clicked'));
          this._map.flyTo({center: coords});
        });

        mapMarker.getElement().addEventListener('mouseover', (e) => {
          el.classList.add('is-hovered');
          cards.forEach(card => card.classList.add('is-hovered'));
        });

        mapMarker.getElement().addEventListener('mouseout', (e) => {
          el.classList.remove('is-hovered');
          cards.forEach(card => card.classList.remove('is-hovered'));
        });

        // clicking a teaser card should pan to the pin & open popup
        cards.forEach(card => {
          card.addEventListener('click', e => {
            // If the click is on a button or link, don't pan or open popup
            const ignoreTypes = ['BUTTON', 'A'];
            if (ignoreTypes.includes(e.target.tagName)) { return; }

            this._map.flyTo({
              center: coords,
            });

            // Open popup
            mapMarker.getPopup().remove();
            mapMarker.getElement().click();
            el.classList.add('is-clicked');

            // update the popup to show the correct provider
            const targetIdx = itemArray.findIndex(item => item.id === card.dataset.id);
            updatePopup(targetIdx);
          });

          card.addEventListener('mouseover', (e) => {
            card.classList.add('is-hovered');
            el.classList.add('is-hovered');
          });

          card.addEventListener('mouseout', (e) => {
            card.classList.remove('is-hovered');
            el.classList.remove('is-hovered');
          });
        });
      }
      if (collapsedMarkers.length >= 2) {
        this._map.fitBounds(bounds, { padding: 50 });
      }
    }
  }

  this._map.loadJS();
}